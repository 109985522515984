import React from "react";
import Seo from "../../components/Seo/Seo";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Section } from "../../components/Core";
import { navigate } from "gatsby";
import orderImage from "./../../assets/image/svg/register-order.svg";

const AppointmentError = () => {
    return (
        <div>
            <Seo
                title="YouFibre | Appointment Error"
                description="YouFibre | Appointment Error"
            />
            <Section className="green-bg text-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} md={10} sm={8}>
                            <img src={orderImage} className="mt-30 mb-30" alt="Order" />
                            <h2>Your installation has already been scheduled.</h2>
                            <p>But if you feel like changing things, let us know.</p>
                            <Button
                                onClick={() => {
                                    navigate('/');
                                }}
                                className="mt-50 light-bg"
                            >Return to homepage</Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </div>
    );
}

export default AppointmentError;
